import { EditOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  FormInstance,
  InputNumber,
  Row,
  Select,
  Table,
  Tabs,
  Typography
} from 'antd';
import TabPane from 'antd/es/tabs/TabPane';
import dayjs from 'dayjs';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import ActionButton from '../../../components/ActionButton';
import BoldButtonLabel from '../../../components/BoldButtonLabel';
import TenantInfo from '../../../components/TenantIdInfo';
import { getAccess, readableString } from '../../../constants/common-constants';
import { convertQueryStringToObj, objectHelpers, urlHelpers } from '../../../helpers';
import _ from '../../../helpers/lodash';
import { displayErrorNotifications, displaySuccessNotification } from '../../../helpers/toast.helpers';
import PrimaryLayout from '../../../layouts/primary-layout';
import { useLoader } from '../../../stores/use-loader';
import { promotionService } from '../services/promotion.service';
import { IPromotionItem, IPromotionsResponse } from '../types/promotion-types';
import './promotion.css';

interface IPromotionListingPageProps {
  promo: string;
  promoName: string;
  productOf?: string;
}

const EditableContext = React.createContext<FormInstance<any> | null>(null);

interface EditableRowProps {
  index: number;
}

const EditableRow: React.FC<EditableRowProps> = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: keyof IPromotionItem;
  record: IPromotionItem;
  handleSave: (record: IPromotionItem) => void;
}

const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = React.useState(false);
  const inputRef = React.useRef<any>(null);
  const form = React.useContext(EditableContext)!;

  React.useEffect(() => {
    if (editing) {
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    if (dataIndex === 'priority') form.setFieldsValue({ [dataIndex]: record[dataIndex] });
    if (dataIndex === 'start_date' || dataIndex === 'end_date') {
      form.setFieldsValue({ [dataIndex]: dayjs(record[dataIndex]) });
    }
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      toggleEdit();
      handleSave({ ...record, ...values });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    if (editing) {
      if (dataIndex === 'priority')
        childNode = (
          <Form.Item
            style={{ margin: 0 }}
            name={dataIndex}
            rules={[
              {
                required: true,
                message: `${title} is required.`
              }
            ]}
          >
            <InputNumber ref={inputRef} onPressEnter={save} onBlur={save} min={0} />
          </Form.Item>
        );
      else if (dataIndex === 'start_date' || dataIndex === 'end_date') {
        childNode = (
          <Form.Item
            style={{ margin: 0 }}
            name={dataIndex}
            rules={[
              {
                required: true,
                message: `${title} is required.`
              }
            ]}
          >
            <DatePicker ref={inputRef} onBlur={save} />
          </Form.Item>
        );
      }
    } else {
      childNode = (
        <div className="cursor-pointer" onClick={toggleEdit}>
          {children}
        </div>
      );
    }
  }

  return <td {...restProps}>{childNode}</td>;
};

const PromotionListingPage: React.FunctionComponent<IPromotionListingPageProps> = ({ promo, promoName, productOf }) => {
  const { permission } = getAccess(productOf || 'NEBULA');
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const [promotionFilterForm] = Form.useForm();

  let keyData = promo === 'LOYT_REWARD' ? 'MANUAL' : 'AUTOMATIC';
  const [key, setKey] = React.useState(keyData);
  const [promotionListResponse, setPromotionListResponse] = React.useState({} as IPromotionsResponse);

  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

  const columns: any = [
    {
      title: t('title'),
      width: '30%',
      dataIndex: 'title',
      align: 'center',
      sorter: (a: any, b: any) => a.title.localeCompare(b.title)
    },
    {
      title: t('rewardType'),
      align: 'center',
      sorter: (a: any, b: any) => a.reward_type.localeCompare(b.reward_type),
      render(value: any, record: any, index: any) {
        let data = readableString(record?.reward_type);
        return <div>{data} </div>;
      }
    },
    {
      title: t('appliesTo'),
      width: '15%',
      // dataIndex: 'application_type',
      align: 'center',
      sorter: (a: any, b: any) => a.application_type.localeCompare(b.application_type),
      render(value: any, record: any, index: any) {
        let data = readableString(record?.application_type);
        return <div>{data} </div>;
      }
    },
    {
      title: t('rank'),
      // width: '5%',
      align: 'center',
      editable: true,
      dataIndex: 'priority',
      sorter: (a: any, b: any) => a.priority - b.priority,
      render(value: any, record: any, index: any) {
        let statusPermission = record.status !== 'CLOSED';
        return (
          <>
            {record.priority}{' '}
            {permission && statusPermission && <EditOutlined style={{ color: '#5885af', fontSize: '16px' }} />}
          </>
        );
      }
    },
    {
      title: t('startDate'),
      width: '12%',
      dataIndex: 'start_date',
      render(value: any, record: any, index: any) {
        let statusPermission = record.status !== 'CLOSED';
        return (
          <>
            {record.start_date}{' '}
            {record.status !== 'ACTIVE' && permission && statusPermission && (
              <EditOutlined style={{ color: '#5885af', fontSize: '16px' }} />
            )}
          </>
        );
      },
      align: 'center',
      editable: true
    },
    {
      title: t('endDate'),
      width: '12%',
      dataIndex: 'end_date',
      render(value: any, record: any, index: any) {
        let statusPermission = record.status !== 'CLOSED';
        const today = new Date();
        const startDate = new Date(record.start_date);
        const endDate = new Date(record.end_date);
        let expDate =
          (record.status !== 'ACTIVE' && (startDate > today || endDate > today)) || record.status === 'ACTIVE';
        return (
          <>
            {record.end_date}{' '}
            {expDate && permission && statusPermission && (
              <EditOutlined style={{ color: '#5885af', fontSize: '16px' }} />
            )}
          </>
        );
      },
      align: 'center',
      editable: true
    },
    {
      title: t('status'),
      // dataIndex: 'status',
      align: 'center',
      sorter: (a: any, b: any) => a.status.localeCompare(b.status),
      render(value: any, record: any, index: any) {
        let data = readableString(record?.status);
        return <div>{data} </div>;
      }
    },
    {
      title: t('action'),
      width: '15%',
      render(value: any, record: any, index: any) {
        let pageRender = location.pathname.includes('new');
        let statusPermission = record.status !== 'CLOSED';
        return (
          <section className="flex flex-wrap justify-center">
            {!pageRender && (
              <ActionButton
                action="VIEW"
                title={t('view')}
                onClick={() => handleViewPromotion(record.id)}
              ></ActionButton>
            )}
            {pageRender && (
              <ActionButton
                action="VIEW"
                title={t('view')}
                onClick={() => handleViewPromotionView(record.id)}
              ></ActionButton>
            )}
            {searchParams.get('promotion_type') === 'COUPON' && (
              <ActionButton
                action="GENERATE_COUPON"
                title="Generate Coupon"
                onClick={() => {
                  handleClickCouponGenerate(record);
                }}
              />
            )}
            {!pageRender && permission && statusPermission && (
              <ActionButton
                action="CREATE_NEW_VERSION"
                title={t('edit')}
                onClick={() => handleEditPromotion(record.id)}
              ></ActionButton>
            )}
            {pageRender && permission && statusPermission && (
              <ActionButton
                action="CREATE_NEW_VERSION"
                title={t('edit')}
                // title="NEW EDIT"
                onClick={() => handleEditPromotionEdit(record.id)}
              ></ActionButton>
            )}
            {/* )} */}
          </section>
        );
      },
      align: 'center'
    }
  ];
  if (key !== 'AUTOMATIC') {
    let placement = columns.length - 3;
    let actionColumn: any = [
      {
        title: t('items'),
        render(value: any, record: any, index: any) {
          return (
            key === 'MANUAL' && (
              <Button type="link" onClick={() => handleClickCouponList(record)}>
                {record.coupon_quantity_to_generate} {t('coupons')}
              </Button>
            )
          );
        },
        align: 'center'
      }
    ];
    columns.splice(placement, 0, ...actionColumn);
  }
  if (permission) {
    let placement = columns.length;
    let actionColumn: any = [
      {
        title: t('statusUpdate'),
        width: '15%',
        render(value: any, record: IPromotionItem, index: any) {
          let actions: { label: string; action: string }[] = [];

          if (record.status === 'OPEN') {
            actions.push({ label: t('activate'), action: 'ACTIVE' });
            actions.push({ label: t('schedule'), action: 'SCHEDULED' });
          }

          if (record.status === 'ACTIVE') {
            actions.push(
              ...[
                {
                  label: 'On Hold',
                  action: 'ON_HOLD'
                },
                { label: t('close'), action: 'CLOSED' }
              ]
            );
          }

          if (record.status === 'SCHEDULED') {
            actions.push(
              ...[
                {
                  label: t('open'),
                  action: 'OPEN'
                }
              ]
            );
          }

          if (record.status === 'ON_HOLD') {
            actions.push({ label: t('activate'), action: 'ACTIVE' });
          }

          return (
            <section className="flex flex-wrap justify-center">
              {actions.map(({ action, label }) => (
                <Button
                  key={action}
                  type="link"
                  onClick={() => handleStatusChange(action, record.id, pageControl.currentPage - 1)}
                >
                  {label}
                </Button>
              ))}
            </section>
          );
        },
        align: 'center'
      }
    ];

    columns.splice(placement, 0, ...actionColumn);
  }

  const [searchParams, setSearchParams] = useSearchParams();
  const queryString = searchParams.toString();
  const queryStringObj = convertQueryStringToObj(queryString);
  const [pageControl, setPageControl] = React.useState<{ pageSize: number; currentPage: number }>(() => {
    const offset = parseInt(searchParams.get('offset') || '0');
    const limit = parseInt(searchParams.get('limit') || '5');

    return {
      currentPage: offset > 0 ? offset + 1 : 1,
      pageSize: limit > 0 ? limit : 5
    };
  });

  const loadAllPromotions = async (offset: number) => {
    setLoading(true);
    promotionFilterForm.setFieldsValue({
      promotion_type: promo,
      order_by: 'desc',
      promo_header_type: promo !== 'LOYT_REWARD' ? 'AUTOMATIC' : 'MANUAL',
      next_order_applicable: false
    });
    handleSearch(offset);
    setLoading(false);
  };

  React.useEffect(() => {
    if (_.isEmpty(queryStringObj)) {
      loadAllPromotions(pageControl.currentPage - 1);
    } else {
      loadPromotionsBasedOnQuery(pageControl?.currentPage - 1);
    }
  }, [pageControl]);

  const loadPromotionsBasedOnQuery = async (offset: number) => {
    promotionFilterForm.setFieldsValue(queryStringObj);
    handleSearch(offset);
  };

  const handleEditPromotion = (id: string) => {
    const backUrl = urlHelpers.getBackUrl(searchParams);
    const promotion = promoName;
    const promo_header_type = promo === 'LOYT_REWARD' ? 'MANUAL' : key;
    const params = new URLSearchParams({ backUrl, promotion, promo_header_type });
    const startStr = '/promotion-engine/promotions/';
    const startIndex = backUrl.indexOf(startStr) + startStr.length;
    const endIndex = backUrl.indexOf('?', startIndex);
    const productName = backUrl.substring(startIndex, endIndex);

    navigate(`/promotion-engine/promotions/${productName}/${id}/edit?${params.toString()}`);
  };

  const handleEditPromotionEdit = (id: string) => {
    const backUrl = urlHelpers.getBackUrl(searchParams);
    const promotion = promoName;
    // setKey
    const promo_header_type = promo === 'LOYT_REWARD' ? 'MANUAL' : key;
    const params = new URLSearchParams({ backUrl, promotion, promo_header_type });
    // const startStr = '/promotion-engine/promotions/';
    // const startIndex = backUrl.indexOf(startStr) + startStr.length;
    // const endIndex = backUrl.indexOf('?', startIndex);
    const productName = backUrl.split('/')[2].split('?')[0];
    navigate(`/promotions/${productName}/${id}/edit?${params.toString()}`);
  };

  const handleStatusChange = async (status: string, promotionId: number, offset: number) => {
    setLoading(true);
    const { errors } = await promotionService.patchPromotionStatus(promotionId, status);
    if (_.isEmpty(errors)) {
      displaySuccessNotification({ message: 'Promotion status updated successfully!' });
      if (pageControl.currentPage == 1) await handleSearch(offset);
      else setPageControl(prev => ({ ...prev, currentPage: 1 }));
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleTabChange = async (offset = 0, keyVal: any) => {
    setLoading(true);
    const formValues = promotionFilterForm.getFieldsValue();
    setPromotionListResponse({} as IPromotionsResponse);
    const sortKey = formValues?.order_by;
    const sortValue = formValues?.sort_by?.toString();
    const dataObject = {
      [sortKey]: sortValue,
      promo_header_type: keyVal,
      type: promo,
      ...formValues
    };
    delete dataObject.order_by;
    delete dataObject.sort_by;
    const filteredFormValues = { ...objectHelpers.deleteUndefinedValuesFromObject(dataObject), offset: offset + '' };
    setSearchParams(filteredFormValues);

    const params = {
      ...filteredFormValues,
      offset,
      limit: 10
    };
    const { data, errors } = await promotionService.getPromotionList(params);
    if (_.isEmpty(errors)) {
      const transformedData = data?.data?.map((item: any) => {
        return {
          ...item,
          key: item?.id
        };
      });
      const transformedResponse: any = {
        ...data,
        data: {
          data: transformedData,
          count: data.count
        }
      };

      setPromotionListResponse(transformedResponse);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const setHeaderType = (headerType: any) => {
    setKey(headerType);
    return headerType;
  };

  const handleSearch = async (offset = 0) => {
    setLoading(true);
    let headerType = queryStringObj.promo_header_type;
    const formValues = promotionFilterForm.getFieldsValue();
    setPromotionListResponse({} as IPromotionsResponse);
    const sortKey = formValues?.order_by;
    const sortValue = formValues?.sort_by?.toString();
    const dataObject = {
      [sortKey]: sortValue,
      promo_header_type: promo !== 'LOYT_REWARD' ? (headerType ? setHeaderType(headerType) : key) : 'MANUAL',
      type: promo,
      ...formValues
    };
    delete dataObject.order_by;
    delete dataObject.sort_by;
    const filteredFormValues = {
      ...objectHelpers.deleteUndefinedValuesFromObject(dataObject),
      offset: offset + '',
      limit: pageControl.pageSize + ''
    };
    setSearchParams(filteredFormValues);

    const params = {
      ...filteredFormValues,
      offset,
      limit: pageControl.pageSize
    };
    const { data, errors } = await promotionService.getPromotionList(params);
    if (_.isEmpty(errors)) {
      const transformedData = data?.data?.map((item: any) => {
        return {
          ...item,
          key: item?.id
        };
      });
      const transformedResponse: any = {
        ...data,
        data: {
          data: transformedData,
          count: data.count
        }
      };

      setPromotionListResponse(transformedResponse);
    } else {
      displayErrorNotifications(errors);
    }
    setLoading(false);
  };

  const handleViewPromotion = async (id: string) => {
    const backUrl = urlHelpers.getBackUrl(searchParams);
    const promotion = promoName;
    const promo_header_type = promo === 'LOYT_REWARD' ? 'MANUAL' : key;
    const params = new URLSearchParams({ backUrl, promotion, promo_header_type });
    const startStr = '/promotion-engine/promotions/';
    const startIndex = backUrl.indexOf(startStr) + startStr.length;
    const endIndex = backUrl.indexOf('?', startIndex);
    const productName = backUrl.substring(startIndex, endIndex);

    navigate(`/promotion-engine/promotions/${productName}/${id}/view?${params.toString()}`);
  };

  const handleViewPromotionView = async (id: string) => {
    const backUrl = urlHelpers.getBackUrl(searchParams);
    const promotion = promoName;
    // setKey
    const promo_header_type = promo === 'LOYT_REWARD' ? 'MANUAL' : key;
    const params = new URLSearchParams({ backUrl, promotion, promo_header_type });
    const startStr = '/promotion-engine/promotions/';
    const startIndex = backUrl.indexOf(startStr) + startStr.length;
    const endIndex = backUrl.indexOf('?', startIndex);
    const productName = backUrl.split('/')[2].split('?')[0];
    navigate(`/promotions/${productName}/${id}/view?${params.toString()}`);
  };

  const handlePageChange = async (current_page: number) => {
    setPageControl(prev => ({ ...prev, currentPage: current_page }));
    const offsetValue = (current_page - 1) * 10;
    await handleSearch(offsetValue);
  };

  const getEditedKey = (previousRowValues: IPromotionItem, currentRowValues: IPromotionItem): string => {
    for (const key in previousRowValues) {
      if (previousRowValues[key as keyof IPromotionItem] !== currentRowValues[key as keyof IPromotionItem]) {
        return key;
      }
    }

    return '';
  };

  const editingActions: Record<string, any> = {
    priority: (id: string, value: string) => promotionService.patchPromotionPriority(id, value),
    start_date: (id: string, value: string) => promotionService.patchPromotionStartDate(id, value),
    end_date: (id: string, value: string) => promotionService.patchPromotionEndDate(id, value)
  };

  const handleSave = async (row: IPromotionItem) => {
    const transformedRow = { ...row };
    if (typeof transformedRow.start_date !== 'string')
      transformedRow.start_date = dayjs(transformedRow.start_date).format('YYYY-MM-DD');

    if (typeof transformedRow.end_date !== 'string')
      transformedRow.end_date = dayjs(transformedRow.end_date).format('YYYY-MM-DD');

    const newData = [...promotionListResponse.data.data];
    const index = newData.findIndex(item => row.key === item.key);
    const item = newData[index];

    const promotionId = row.id;
    const editedKey = getEditedKey(item, transformedRow);

    if (editedKey === '') return;

    setLoading(true);
    const { errors } = await editingActions[editedKey](promotionId, transformedRow[editedKey as keyof IPromotionItem]);
    setLoading(false);

    if (!_.isEmpty(errors)) {
      displayErrorNotifications(errors);
      return;
    }

    newData.splice(index, 1, {
      ...item,
      ...transformedRow
    });
    setPromotionListResponse(response => {
      return {
        ...response,
        data: {
          ...response.data,
          data: newData
        }
      };
    });

    displaySuccessNotification({ message: 'Promotion update success' });
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  };
  const columnsEdited = columns.map((col: any) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: IPromotionItem) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave
      })
    };
  });

  const handleClickCouponList = (row: IPromotionItem) => {
    const { reward_type, promotion_type, title, id } = row;
    const params = {
      reward_type,
      promotion_type,
      title,
      offset: '0',
      id: id + '',
      backurl: location?.pathname,
      promo_header_type: key
    };
    const paramString = new URLSearchParams(params).toString();
    navigate(`/promotion-engine/coupons?${paramString}`);
  };

  const handleClickCouponGenerate = (row: IPromotionItem) => {
    const { promotion_type, id } = row;
    const params = {
      promotion_type,
      back_to_promotion_listing: 'true',
      offset: '0',
      id: id + ''
    };

    const paramString = new URLSearchParams(params).toString();

    navigate(`/promotion-engine/promotions/${row.id}/generate-coupons?${paramString}`);
  };

  const handleOnTabClick = (val: any) => {
    setKey(val);
    handleTabChange(0, val);
  };

  return (
    <PrimaryLayout>
      <div className="container mx-auto px-4">
        <Card className="shadow-md">
          <Form
            form={promotionFilterForm}
            onFinish={() =>
              pageControl.currentPage === 1 ? handleSearch(0) : setPageControl(prev => ({ ...prev, currentPage: 1 }))
            }
            layout="vertical"
          >
            {promo !== 'LOYT_REWARD' ? (
              <Tabs activeKey={key} onChange={handleOnTabClick}>
                <TabPane tab={<BoldButtonLabel labelText={t('automatic')} />} key="AUTOMATIC"></TabPane>
                <TabPane tab={<BoldButtonLabel labelText={t('manual')} />} key="MANUAL"></TabPane>
              </Tabs>
            ) : (
              <Tabs activeKey={key} type="card">
                <TabPane tab={<BoldButtonLabel labelText={t('manual')} />} key="MANUAL"></TabPane>
              </Tabs>
            )}
            <Row justify={'space-between'} className="flex items-center mt-4">
              <Col>
                <Typography.Title level={3} className="text-[#2e2a5b]">
                  {t('promotion')} : {promoName}
                </Typography.Title>
              </Col>
              {permission && (
                <Col xs={24} md={6}>
                  <Button
                    type="primary"
                    block
                    size="large"
                    onClick={() => {
                      const backUrl = urlHelpers.getBackUrl(searchParams);
                      let promotion_type = promo;
                      const promotionName = promoName;
                      const paramString = new URLSearchParams({
                        promotion_type,
                        promotionName,
                        promo_header_type: promo === 'LOYT_REWARD' ? 'MANUAL' : key,
                        backUrl
                      }).toString();

                      if (location.pathname.includes('new')) {
                        const startStr = '/promotions/';
                        const startIndex = backUrl.indexOf(startStr) + startStr.length;
                        const endIndex = backUrl.indexOf('?', startIndex);
                        const productName = backUrl.substring(startIndex, endIndex);
                        navigate(`/promotions/create/${productName}?${paramString}`);
                      } else {
                        const startStr = '/promotion-engine/promotions/';
                        const startIndex = backUrl.indexOf(startStr) + startStr.length;
                        const endIndex = backUrl.indexOf('?', startIndex);
                        const productName = backUrl.substring(startIndex, endIndex);
                        navigate(`/promotion-engine/promotions/${productName}/create?${paramString}`);
                      }
                    }}
                  >
                    <BoldButtonLabel labelText={t('createPromotion')} />
                  </Button>
                </Col>
              )}
            </Row>
            <div className="mt-4">
              <TenantInfo />
            </div>
            <Row gutter={12} className="mt-4">
              <Col xs={24} md={6}>
                <Form.Item name={'status'} label={t('status')}>
                  <Select
                    allowClear
                    placeholder={t('select')}
                    size="large"
                    options={[
                      { label: 'Open', value: 'OPEN' },
                      { label: 'Active', value: 'ACTIVE' },
                      { label: 'Closed', value: 'CLOSED' },
                      { label: 'Scheduled', value: 'SCHEDULED' },
                      { label: 'Expired', value: 'EXPIRED' },
                      {
                        label: 'On Hold',
                        value: 'ON_HOLD'
                      }
                    ]}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[12, 12]}>
              <Col xs={24} md={4}>
                <Button block htmlType="submit" type="primary" size="large">
                  <BoldButtonLabel labelText={t('search')} />
                </Button>
              </Col>
            </Row>
            <Table
              loading={false}
              bordered
              components={components}
              pagination={{
                current: pageControl?.currentPage,
                total: promotionListResponse?.data?.count || 0,
                pageSize: pageControl?.pageSize,
                showSizeChanger: true,
                pageSizeOptions: ['1', '5', '10', '20', '50', '100'],
                onChange: (page, pageSize) => {
                  setPageControl({
                    currentPage: page,
                    pageSize
                  });
                }
              }}
              className="mt-4"
              dataSource={promotionListResponse.data?.data || []}
              columns={columnsEdited}
              scroll={{ x: 1000 }}
            />
            {/* </Card> */}
          </Form>
        </Card>
      </div>
    </PrimaryLayout>
  );
};

export default PromotionListingPage;
