import forge from 'node-forge';
import { useAuth } from '../stores/use-auth';

export const JAVA_MAX_INT = 2147483647;

export const PUBLICKEY = `-----BEGIN PUBLIC KEY-----
                    MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA4GCt4K59fqWTW/PahKux
                    9cIGXCorQhOxGqPjEmnDgLyNkBZMEUz1kJwi1By5ojUxRXjmI5LRyDJ/D6HIBWUQ
                    4Thss3apqgdfqp2BuNB+ia/2uzXwDFgiyIvlYU/GPakXvFQGNQ40IoHYOODVwLCb
                    vTnELmP8wrDQUNTyR7PFgx5zGLF2iO5i87iy2iz6DzrBkZfxtUWBlIHkh1J/2Rtv
                    i1AJj6iKLS3p/k2WOF7RqMlpcWBtGqYJ3/GhuDDtb3nOdIv2XHfVvVBHCBFNv1Hh
                    GK6G9+dk2RER5SlGm5vtdbgoPXJtKgGyPY6xHMYtPa2Akz2t/stb4ZWQ8IdWxw2m
                    xwIDAQAB
                    -----END PUBLIC KEY-----`;

export const MAX_LIMIT_PROMO = 999999999;

export const encryptData = (encryptKey: any) => {
  const publicKey = forge.pki.publicKeyFromPem(PUBLICKEY);
  //---------------------------CONST PUBLICKEY -----------------------------
  // Convert the input text to a byte buffer
  const buffer = forge.util.createBuffer(encryptKey, 'utf8');
  // Encrypt the buffer using RSA-OAEP
  const encrypted = publicKey.encrypt(buffer.getBytes(), 'RSA-OAEP', {
    md: forge.md.sha256.create(),
    mgf1: forge.mgf.mgf1.create(forge.md.sha256.create())
  });
  // Convert the encrypted data to base64 for display
  const base64 = forge.util.encode64(encrypted);
  return base64;
};

type RoleAccess = { [key: string]: string };

export const getAccess = (productOf: string) => {
  let roleAccess = useAuth.getState()?.roleAccess || '';
  if (Array.isArray(roleAccess)) {
    const access = roleAccess.find((item: RoleAccess) => item[productOf]);
    const permission = access && (access[productOf] === 'ROOT' || access[productOf] === 'ADMIN');
    if (permission) {
      return { permission: true };
    } else {
      return { permission: false };
    }
  } else {
    return { permission: false };
  }
};

export const readableString = (str: string) => {
  const words = str.match(/[a-zA-Z]+/g) || [];
  return words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
};
