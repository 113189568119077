import { ConfigProvider } from 'antd';
import 'antd/dist/reset.css';
import enUS from 'antd/es/locale/en_US';
import esES from 'antd/es/locale/es_ES';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import 'dmn-js/dist/assets/diagram-js.css';
import 'dmn-js/dist/assets/dmn-font/css/dmn.css';
import 'dmn-js/dist/assets/dmn-js-decision-table-controls.css';
import 'dmn-js/dist/assets/dmn-js-decision-table.css';
import 'dmn-js/dist/assets/dmn-js-drd.css';
import 'dmn-js/dist/assets/dmn-js-literal-expression.css';
import 'dmn-js/dist/assets/dmn-js-shared.css';
import { useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { urlHelpers } from './helpers';
import _ from './helpers/lodash';
import { router } from './router';
import { profileService } from './services';
import { loggingIn } from './services/login.services';
import { useAuth, useTenant } from './stores/use-auth';
import { useLoader } from './stores/use-loader';
import { useLocale } from './stores/use-locale';

dayjs.extend(utc);
dayjs.extend(timezone);

function App() {
  const {
    setTenent,
    setIsLoggedIn,
    accessTokenData,
    setAccessToken,
    setBussinessGroupName,
    setProfileName,
    setUserMail,
    setUserName,
    tenantSecret,
    setTenantSecret,
    resetAuthStates,
    setRoleAccess
  } = useAuth(
    ({
      tenentName,
      setTenent,
      setIsLoggedIn,
      accessTokenData,
      setAccessToken,
      setBussinessGroupName,
      setProfileName,
      setUserMail,
      setUserName,
      tenantSecret,
      setTenantSecret,
      resetAuthStates,
      setRoleAccess
    }) => ({
      tenentName,
      setTenent,
      setIsLoggedIn,
      accessTokenData,
      setAccessToken,
      setBussinessGroupName,
      setProfileName,
      setUserMail,
      setUserName,
      tenantSecret,
      setTenantSecret,
      resetAuthStates,
      setRoleAccess
    })
  );
  const { setUserRole, setMemberOf, setResourceAccess } = useTenant(
    ({ setUserRole, setMemberOf, setResourceAccess }) => ({ setUserRole, setMemberOf, setResourceAccess })
  );
  const { setLoading } = useLoader(({ setLoading }) => ({ setLoading }));

  const { businessGroupId: businessGrpIdFromUrl, tenantId: tenandIdFromUrl } =
    urlHelpers.getTenantIdAndBusinessGroupIdFromUrl();
  const { locale, setUserLocale } = useLocale(({ locale, setUserLocale }) => ({ locale, setUserLocale }));

  const verifyToken = async () => {
    setLoading(true);
    const { data: tenantSecretData } = await profileService.getTenantSecret({
      businessGroupId: businessGrpIdFromUrl,
      tenantId: tenandIdFromUrl
    });
    const tenantSecret = tenantSecretData?.secret;
    setTenantSecret(tenantSecret);

    if (accessTokenData) {
      const { data, errors } = await loggingIn.getintrospect({ tenantSecret });
      if (_.isEmpty(errors)) {
        let accessManager = data?.member_of?.map((item: any) => {
          let splitRole = item.split('/');
          let key = splitRole[2].toUpperCase();
          let val = splitRole[splitRole.length - 1];
          return { [key]: val };
        });
        setProfileName(data?.name);
        setUserMail(data?.email);
        setTenent(data?.tenant_profile_name);
        setUserRole(data?.realm_access?.roles);
        setUserName(data?.username);
        setBussinessGroupName(data?.tenant_business_grp_name);
        setMemberOf(data?.member_of);
        setResourceAccess(data?.resource_access);
        // console.log('accessManager', accessManager);
        setRoleAccess(accessManager);
        if (!data.active) {
          resetAuthStates();
        }
      } else {
        resetAuthStates();
      }
    } else {
      resetAuthStates();
    }
    setLoading(false);
  };

  useEffect(() => {
    verifyToken();
  }, []);

  const localeCodeForAntdTranlsation: any = {
    CL: esES,
    IN: enUS
  };

  const localeForMomentTranslation: any = {
    CL: 'es',
    IN: 'en'
  };
  dayjs.locale(localeForMomentTranslation[locale] || 'en');
  return (
    <ConfigProvider
      locale={localeCodeForAntdTranlsation[locale] || enUS}
      theme={{
        token: { fontFamily: `Inter`, colorPrimary: '#008080' },
        components: {
          Button: {
            colorPrimary: '#008080',
            colorPrimaryBgHover: '#008888',
            colorPrimaryHover: '#016161',
            colorPrimaryActive: '#004d4d'
          },
          Steps: {
            colorPrimary: '#5885AF',
            colorPrimaryBgHover: '#7399bc',
            colorPrimaryHover: '#7399bc',
            colorPrimaryActive: '#5885AF'
          },
          Input: {
            fontFamily: 'Inter, sans-serif'
          },
          InputNumber: {
            fontFamily: 'Inter, sans-serif',
            borderRadius: 0
          },
          Menu: {}
        }
      }}
    >
      <RouterProvider router={router} />
    </ConfigProvider>
  );
}

export default App;
