import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface IAuth {
  isLoggedIn: boolean;
  setIsLoggedIn: (isLoggedIn: boolean) => void;
  accessTokenData: null;
  setAccessToken: (token: string | any) => void;
  tenentName: string;
  setTenent: (name: string) => void;
  bussinessGroupName: string;
  setBussinessGroupName: (name: string) => void;
  profileName: string;
  setProfileName: (name: string) => void;
  userMail: string;
  setUserMail: (mail: string) => void;
  userName: string;
  setUserName: (name: string) => void;
  resetAuthStates: () => void;
  tenantSecret?: string;
  setTenantSecret: (tenantSecret: string) => void;
  roleAccess?: {
    [key: string]: string;
  };
  setRoleAccess: (roleAccess: { [key: string]: string }) => void;
}

interface TenentDetails {
  userRoles: string[];
  member_of: string[];
  resourceAccess: any;
  setUserRole: (roles: any) => void;
  setMemberOf: (member_of: string[]) => void;
  setResourceAccess: (resourceAccess: any) => void;
}

interface IClientInfo {
  clientEmail: string;
  setClientEmail: (name: string) => void;
  resetToken: string;
  setResetToken: (name: string) => void;
}

export const useAuth = create<IAuth>()(
  persist(
    set => ({
      isLoggedIn: false,
      setIsLoggedIn: isLoggedIn => set({ isLoggedIn }),
      accessTokenData: null,
      setAccessToken: accessTokenData => set({ accessTokenData }),
      tenentName: '',
      setTenent: name => set({ tenentName: name }),
      bussinessGroupName: '',
      setBussinessGroupName: name => set({ bussinessGroupName: name }),
      profileName: '',
      setProfileName: name => set({ profileName: name }),
      userMail: '',
      setUserMail: mail => set({ userMail: mail }),
      userName: '',
      setUserName: name => set({ userName: name }),
      resetAuthStates: () =>
        set({
          isLoggedIn: false,
          accessTokenData: null,
          tenentName: '',
          bussinessGroupName: '',
          profileName: '',
          userMail: '',
          userName: '',
          roleAccess: {}
        }),
      tenantSecret: undefined,
      setTenantSecret: tenantSecret => set({ tenantSecret }),
      roleAccess: {},
      setRoleAccess: roleAccess => set({ roleAccess })
    }),

    { name: 'user-auth' }
  )
);

export const useTenant = create<TenentDetails>(set => ({
  userRoles: [],
  member_of: [],
  resourceAccess: null,
  setUserRole: userRoles => set({ userRoles }),
  setMemberOf: member_of => set({ member_of }),
  setResourceAccess: resourceAccess => set({ resourceAccess }),
  handleOnLogOut: () => {}
}));

export const useClientInfo = create<IClientInfo>()(
  persist(
    set => ({
      clientEmail: '',
      setClientEmail: email => set({ clientEmail: email }),
      resetToken: '',
      setResetToken: token => set({ resetToken: token })
    }),
    {
      name: 'clientEmail'
    }
  )
);
